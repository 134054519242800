@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Crocante;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/crocante.woff") format("woff");
  }

  @font-face {
    font-family: TTFirs;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/fonts/ttfirs-extrabold.woff") format("woff");
  }

  @font-face {
    font-family: TTFirs;
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/fonts/ttfirs-bold.woff") format("woff");
  }

  html {
    font-family: TTFirs;
  }

  *,
  *::before,
  *::after {
    outline: none;
  }

  *:focus-visible {
    outline: none;
  }

  body {
    min-height: 100vh;
    @apply text-daintree;
  }

  svg {
    display: inline;
  }

  input,
  textarea {
    @apply outline-none;
  }
  hr {
    border: none;
    border-top: 1px solid transparent;
    margin: 0;
    padding: 0;
  }
}
